#movimentoShowTable {
    height: 35vh;
    tr.firstRow {
        max-height: 175px;
    }
    tr.small {
        max-height: 200px;
        td:first-child {
            width: 225px;
            height: 200px;
            p {
                margin-bottom: 0
            }
        }
        td:last-child {
            max-width: 300px;
            height: 200px;
            p {
                margin-bottom: 0
            }
        }
    }
}

.dataTables_length label {
    display: flex;
    align-items: center;
    gap: 6px;
    & > select { min-width: 75px; border: inherit; border-radius: inherit; padding: inherit; background-color: inherit; }
}

.dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_filter input {
    border: 1px solid $input-border-color !important;
    border-bottom-width: 2px !important;
    border-radius: $input-border-radius !important;
    &:focus, &:active {
        border-color: $input-focus-border-color !important;
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: transparent !important;
    box-shadow: none !important;
}

.dataTables_wrapper .dataTables_processing.card
{
    height: 100%;
    top: 0;
    //padding-top: 50%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    box-shadow: none !important;
}
