.min-vh-25 {
    min-height: 25vh !important;
}

.mb-7 {
    margin-bottom: 5rem !important;
}

.fs-0 {
    font-size: 1rem !important;
}

.text-500 {
    color: grey !important;
    font-weight: bold;
}