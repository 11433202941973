#frontespizio_intermediazione,
#frontespizio_carico_scarico {
        
    .header {
        border-bottom: 3px solid black;
    }
    h4 {
        margin-bottom: 0;
    }

    #classi_pericolosita:last-child {
        max-width: 33%;
    }

    input[type="text"]:not(input[name="ragione_sociale"]) {
        width: 100%;
    }

    input[type="date"] {
        min-width: 130px;
    }

    .section-fourth {
        label {
            font-weight: bold;
            margin: 0 10px;
        }
        input[type="text"] {
            max-width: 150px;
        }
    }

    .borderless-input {
        border: none;
        background-color: #f8fafc;
        pointer-events: none
    }

    .highlight,
    span[selected] {
        background-color: yellow;
        text-decoration: underline;
    }
    
    .ordered-list li * {
        display: inline;
    }
}