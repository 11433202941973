#navbarSupportedContent .left-side-navbar {
    a {
        color: $grey;
        font-weight: bold;
        // font-size: 1.05rem;
        text-decoration: none;
    }
    .nav-item:not(:first-child) > a::before   {
        //content: "/";
    }
}
