.card-body.right {
    div {
        min-height: 66%;
        &.smaller {
            min-height: 33%;
            max-height: 33%;
        }
    }
    .col-md-8 p,
    .col-md-4 p {
        // font-size: 1.25rem;
        color: grey;
        height: 50%;
        span {
            color: black;
            display: block;
        }
    }
}

#showAllMovimenti {
    label {
        margin-bottom: 0;
    }
    button[data-toggle="tooltip"],
    button[data-bs-toggle="tooltip"] {
        min-height: 37px;
        min-width: 40px;
    }
}

.legenda {
    margin-right: 20px;
    li {
        margin-bottom: 10px;
    }
    pointer-events: none;
}
.movimenti-intermediazione tbody tr,
.movimenti-carico-scarico tbody tr {
    td {
        vertical-align: middle;
        &:not(:first-of-type),
        &:not(:last-of-type) {
            border-left: 1px solid grey;
        }
        &:not(:last-of-type) {
            border-right: 1px solid grey;
        }
        &:last-of-type a {
            text-decoration: none;
        }
    }
}

.movimenti-correzioni tr {
    td {
        &:not(:first-of-type) {
            border-left: 1px solid grey;
        }
        &:not(:last-of-type) {
            border-right: 1px solid grey;
        }
    }
}

.logo {
    height: 150px;
    img {
        height: 100%;
        object-fit: contain;
    }
}

#svgTest {
    height: 300px;
    img {
        height: 100%;
        object-fit: contain;
    }
}

#profile-banner {
    padding: 0;
    .shade {
        position: absolute;
        inset: 0;
        background: linear-gradient(
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.7) 80%,
            #ffffff
        );
    }
    .bg-holder {
        height: clamp(350px, 20vh, 450px);
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        background-size: cover;
        // image source https://unsplash.com/photos/red-yellow-and-green-trash-bins-FoG7PKNYjpM
        background-image: url("/img/registro-sfondo.jpeg");
        background-position-y: center;
        // margin-bottom: 150px;
    }
    .avatar-profile {
        height: 150px;
        width: 150px;
        flex-shrink: 0;
        @media screen and (max-width: 800px) {
            height: 100px;
            width: 100px;
        }
        @media screen and (max-width: 600px) {
            height: 75px;
            width: 75px;
        }
        background-color: white;
        display: block;
        border-radius: 1000px;
        padding: 0;
        border: 1px solid #dadada;
        border-bottom: 2px solid #dadada;
        &:hover {
            border-bottom: 1px solid #dadada;
            border-top: 2px solid #dadada;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            background-position: center;
            overflow: hidden;
        }
    }
}

.wrapper {
    // max-width: 1024px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    &.wrapper-navbar {
        // width: 1024px;
        width: 1280px;
    }
}

a.datatable-link {
    color: #2a73b0;
    text-decoration: none;
}

a.datatable-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.btn.btn-info {
    padding-left: 1rem;
    padding-right: 1rem;
}

.annullato {
    color: red;
    position: absolute;
    opacity: 0.3;
    font-size: 10rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* top: 35%;
    left: 10%;
    transform: rotate(15deg); */
}

.standout {
    font-weight: bold;
    text-decoration: underline;
}

.popover {
    white-space: pre-line !important;
}

#anagrafiche-datatable {
    td {
        vertical-align: middle;
        &:not(:first-of-type) {
            border-left: 1px solid grey;
        }
        &:not(:last-of-type) {
            border-right: 1px solid grey;
        }
    }
}

// #createMovimentoButton,
// #vaiACreateIntermediazione,
// #editMovimentoButton {
/* .btn-primary {
    background-color: $mynewcolor !important;
    cursor: pointer;
    color: #fefefe !important;
    &:hover {
        transition: .3s;
        background-color: $mynewcolorhover !important;
        color: #fefefe !important;
    }
}
.btn-info {
    color: white !important;
}*/

#app {
    height: 100%;
}

body {
    max-height: 100vh;
    // height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;

    // position: relative;
    // height: calc(100vh - 125px);
}

nav {
    height: $topNavbarHeight;
    max-height: $topNavbarHeight;
    .navbar-container {
        max-height: $topNavbarHeight;
        overflow: hidden;
    }
}

main {
    //min-height: 100vh - ($altezzaFooter + $altezzaNavbar + $bottomMarginNavbar + bottomMarginMain)
    min-height: calc(100vh - ($footerHeight + $topNavbarHeight + 16px + 48px));
    overflow-x: hidden;
    // height: calc(100vh - 80px);
    // max-height: calc(100vh - 80px);
}

.alert-stampa {
    display: none;
}

.info-baloon {
    display: none;
    color: red;
    top: 0;
    right: 0;
    width: 200px;
    transform: translate(20%, -150%);
    border-radius: 5px;
}

.print-button-overlay {
    z-index: 100;
    background-color: transparent;
    height: 100%;
    width: 55%;
    left: 0;
    top: 0;
}

.biroser-modal {
    .modal-header {
        background-color: $biroserDarkGreen !important;
    }
    button:last-of-type {
        background-color: $biroserDarkGreen !important;
        &:hover {
            background-color: $biroserDarkGreenHover !important;
        }
    }
}

//COLORS
.text-teal {
    color: $teal;
}

.text-indigo {
    color: $indigo;
}

.bg-orange {
    background-color: $darkOrange;
}

.modulo-registro-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: auto;
    height: 21cm;
    width: 29.7cm;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    @media print {
        box-shadow: none;
        margin: 0;
    }
    .fields-wrapper {
        display: flex;
        flex-flow: row;
        .modulo-registro-column {
            position: relative;
            overflow: hidden;
            > div {
                position: absolute;
                font-size: 6pt;
            }
        }
        .modulo-registro-frontespizio-content {
            position: absolute;
            inset: 0;
            overflow: hidden;
            > div {
                position: absolute;
                font-size: 9pt;
            }
        }
    }
}

.modulo-registro-pages-wrapper {
    row-gap: 16px;
    padding: 16px;
    min-height: 100vh;
    @media print {
        padding: 0;
        row-gap: 0;
    }
}
