#landing-page {
    h2 {
        font-weight: bold;
        color: $biroserDarkGreen;
    }
}

a[data-bs-target="#loginModal"] {
    @media (max-width: 350px) {
        display: none;
    }
}

.landingPageButton {
    background-color: $landingPageButton;
    border-radius: 20px;
    margin-top: 20px;
    color: $biroserDarkGreen;
    text-decoration: none;
    text-align: center;
    .fa-chevron-up {
        display: none;
    }
    @media (min-width: 768px) {
        min-width: 300px;
    }

    &:hover {
        background-color: $landingPageButtonHover;
        transition: .3s;
        color: $biroserDarkGreen !important;
        cursor: pointer;
    }
    h5 {
        margin: 10px 0;
        text-transform: uppercase;
    }
}

.contattaci-button {
    background-color: $biroserDarkGreen;
    color: $landingPageButton;
    border-radius: 20px;
    &:hover {
        color: $landingPageButton !important;
        background-color: $biroserDarkGreenHover;
    }
}

.welcome-hero {
    // height: clamp(300px, 40vh, 600px);
    // background-image: url('/img/44261.jpg');
    // height: clamp(500px, 60vh, 800px);
    // background-image: url('/img/trash-truck.jpg');
    // background-size: cover;
    // background-repeat: no-repeat;
    // overflow: hidden;
    // background-position: center -250px;

    .banner {
        background: linear-gradient(to right, rgb(153,197,181), rgb(234,230,159));
        position: relative;

        h5,h2 {
            color: $biroserDarkGreen;
        }

        p {
            font-size: 1.125rem;
            padding: 0 15px;
        }
        #carouselBannerCaptions {
            h4,h5 {
                color: $biroserDarkGreen;
            }
            img {
                margin-bottom: 115px;
            }
            @media (max-width: 465px) {
                img {
                    margin-bottom: 135px;
                }
            }
            @media (max-width: 370px) {
                img {
                    margin-bottom: 150px;
                }
            }
            @media (max-width: 325px) {
                img {
                    margin-bottom: 175px;
                }
            }
        }
    }
}
.bg-oval {
    height: 100px;
    overflow: hidden;
    background-color: #ffffff;
    .oval-shaped {
        position: relative;
        // height: 100px;
        height: 175px;
        transform: translateY(-50%);
        background: linear-gradient(to right, rgb(153,197,181), rgb(234,230,159));
        -webkit-clip-path: ellipse(54% 40% at 50%);
        clip-path: ellipse(54% 40% at 50%);
    }
}

.welcome {
    @media (max-width: 767px) {
        h2 {
            margin-top: 25px;
        }
    }
    .row.top,
    .row.center,
    .row.bottom {
        background-color: #ffffff;
        height: 100%;
        padding: 0 1rem;
        @media (max-width: 767px) {
            h2, h5 {
                width: 100%;
                text-align: center;
            }
        }
    }

    .row.top,
    .row.center,
    .row.bottom,
    .banner {
        // height: clamp(300px, 40vh, 600px);
        h1 {
            font-weight: 700;
        }

        h4 {
            word-wrap: break-word;
        }

        h1,
        h4 {
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            color: #5e5e5e;
        }

        .welcome-logo > div{
            height: clamp(300px, 25vh, 500px);
            img{
                // height: 75%;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
    .wrapper {
        // max-width: 1024px !important;
        max-width: 1280px;
    }
}

#login-modal {
    min-width: 40vw;
}

a[data-bs-target="#loginModal"]:hover {
    cursor: pointer;
}

#nav-logo {
    height: 60px;
    min-height: 60px;
    width: 240px;
    min-width: 240px;
    background-image: url('/img/biroser-color.svg');
    background-size: cover;
    background-position: center;
}

#info-button {
    // background-color: #ffd301;
    background-color: $biroserDarkGreen;
    font-weight: 700;
    // color: white;
    color: $landingPageButton;
    border-radius: 5px;
    font-size: 1.25rem;
    &:hover {
        // background-color: #efcc20;
        background-color: $biroserDarkGreenHover;
    }
}

#leaves,
#water,
#earth {
    display: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .icon {
        height: 70px;
        img,
        object {
            object-fit: cover;
            height: 100%;
        }
        img.small {
            width: 75px;
        }
    }
    h2 {
        color: unset;
    }
}

#leaves {
    background-image: url('/img/funzionalita/leaves.jpg');
}

#water {
    background-image: url('/img/caratteristiche/Sfondo funzionalità.png');
    @media (min-width: 768px) {
        background-size: auto;
    }
}

#earth {
    background-image: url('/img/keywords/earth.jpg');
}

#carouselLeavesCaptions {
    img {
        margin-bottom: 75px;
    }
    @media (max-width: 565px) {
        img {
            margin-bottom: 100px;
        }
        .carousel-item {
            min-height: 250px;
        }
    }
    @media (max-width: 465px) {
        img {
            margin-bottom: 125px;
        }
        .carousel-item {
            min-height: 275px;
            i {
                margin-bottom: 3rem !important
            }
        }
    }
    @media (max-width: 380px) {
        img {
            margin-bottom: 150px;
        }
        .carousel-item {
            min-height: 300px;
        }
    }
    @media (max-width: 325px) {
        img {
            margin-bottom: 175px;
        }
        .carousel-item {
            min-height: 325px;
        }
    }
}

#carouselWaterCaptions {
    img {
        margin-bottom: 115px;
    }
    @media (max-width: 540px) {
        img {
            margin-bottom: 135px;
        }
    }
    @media (max-width: 400px) {
        img {
            margin-bottom: 150px;
        }
    }
    @media (max-width: 355px) {
        img {
            margin-bottom: 175px;
        }
    }
    @media (max-width: 354px) {
        img {
            margin-bottom: 200px;
        }
    }
}

#carouselEarthCaptions {
    img {
        margin-bottom: 115px;
    }
    @media (max-width: 465px) {
        img {
            margin-bottom: 135px;
        }
    }
    @media (max-width: 370px) {
        img {
            margin-bottom: 150px;
        }
    }
    @media (max-width: 325px) {
        img {
            margin-bottom: 175px;
        }
    }
}



noscript {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background-color: rgba(0,0,0, .5);

    #noJsAlert {
        position: fixed;
        width: 50%;
        height: 35vh;
        max-width: 600px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $biroserDarkGreen;
        color: white;
        border-radius: 20px;
        box-shadow: 1px 1px #ffffff;

        .card-header {
            position: relative;
            background-color: #ffffff;
            height: 80px;
            overflow: hidden;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            .img-container {
                height: 250px;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    object-fit: contain;
                    height: 250px;
                }
            }
        }
    }
}


.landingPageButton .fa-chevron-up, .toggleEarth.fa-chevron-up, .toggleWater.fa-chevron-up, .toggleLeaves.fa-chevron-up {

    color: rgba($color: #ffffff, $alpha: .6);
    font-size: 3rem;
    cursor: pointer;
    &:hover {
        color: #fff;
        transition: .3s;
    }
}


.custom-card {
    width: calc(100% / 3 - 60px);
    color: white;
    i {
        font-size: 2.125rem;
        margin-bottom: 1rem;
    }
    h4 {
        margin-top: -15px;
    }
}

.carousel {
    display: none;
}

.header-cards h5 {
    margin-top: -25px;
}

.header-cards p {
    margin-top: 25px;
}

#browserDetection .modal-header{
    max-height: 80px;
    overflow: hidden;
    .img-container {
        height: 200px;
        img {
            height: 100%;
            object-fit: contain;
        }
    }
}

.login-logo {
    width: 300px;
    height: 150px;
    background-image: url('/img/biroser-color-white-text.svg');
    background-size: cover;
    background-position-y: center;
}

// .hero2 {
//     background: url("/img/bulb.webp") rgba(0, 102, 58, 0.9);
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center center;
//     background-blend-mode: multiply;
// }

// .placeholder {
//     height: 200px;
//     &.placeholder-top {
//         background: linear-gradient(to left bottom, #ffffff 49.9%, transparent 50.1%);
//     }
//     &.placeholder-bottom {
//         background: linear-gradient(to left bottom, transparent 49.9%, #ffffff 50.1%);
//     }
// }

@media (max-width: 767px) {
    // .bg-oval {
    //     height: 50px;
    // }
    .carousel {
        display: block;
    }
    .carousel-item {
        min-height: 240px;
        img {
            height: 150px;
            margin-bottom: 25px;
        }
    }


    // .piano-menu {
    //     background-image: url('/img/keywords/earth.jpg');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-position:center center ;
    //     .icons-container div{
    //         width: calc(100% / 6);
    //         height: 124px;
    //         overflow: hidden;
    //         border-bottom: 3px solid white;
    //         border-right: 1px solid white;
    //         border-left: 1px solid white;
    //         img {
    //             width: 100%;
    //             height: 100%;
    //         }
    //     }
    //     .collapsible {
    //         color: white;
    //         text-align: center;
    //         // display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         justify-content: center;
    //     }
    // }

    .active-img {
        transform: scale(1.2);
        transition: .3s;
    }
    .invisible-border {
        border-bottom: 3px solid transparent !important;
    }
    .h-0 {
        height: 0;
    }
}

@media (max-width: 500px) {
    footer ul {
        text-align: center;
        width: 100%;
    }
}
