// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

// Variables
@import "variables";

// Commons
@import "commons";

//Theme classes styles
@import "themeClasses";

//Navbar
@import "navbar";

//Landing page
@import "welcome";

//Template fronstespizio stampa registro intermediazione
@import "layouts_registri/frontespizio_intermediazione";

//Segnalazioni
@import "segnalazioni";

//Informativa sui Cookies
@import "informativa_cookies";

//Popup cookies di navigazione
@import "cookie-consent";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

//Stili tables e datatables
@import "tables";

@import "~select2/dist/css/select2.min.css";

.bg-image {
    /*background: url({{ asset('storage/pile_accumulatori.jpeg') }}) no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #ebeeef;
    box-shadow: 0 0 8px 8px white inset;
    height: 100%;
}

// .btn-primary {
//   @include button-variant($mynewcolor, darken($mynewcolor, 7.5%), darken($mynewcolor, 10%), lighten($mynewcolor,5%), lighten($mynewcolor, 10%), darken($mynewcolor,30%));
//   color: white;
// }

/* .btn-outline-primary {
  @include button-outline-variant($mynewcolor, #222222, lighten($mynewcolor,5%), $mynewcolor);
} */

.paginate_button,
.page-item,
.previous {
    box-sizing: inherit !important;
    display: inherit !important;
    min-width: inherit !important;
    padding: inherit !important;
    margin-left: inherit !important;
    text-align: inherit !important;
    text-decoration: inherit !important;
    cursor: inherit !important;
    *cursor: inherit !important;
    color: inherit !important;
    border: inherit !important;
    border-radius: inherit !important;
}

// OVERRIDES

// Nav
.nav-pills .nav-link {
    border-bottom: 2px solid shade-color($light, 5%);
    border-left: 1px solid shade-color($light, 2%);
    border-right: 1px solid shade-color($light, 2%);
    background-color: $light;
}

.nav-pills .nav-link.active {
    border-top: 2px solid shade-color($primary, 25%);
    border-left: 1px solid shade-color($primary, 5%);
    border-right: 1px solid shade-color($primary, 5%);
    border-bottom: 0;
}

// Button
.btn {
    border-bottom-width: 2px;
    &:hover,
    &:focus,
    &:active {
        border-top-width: 2px;
        border-bottom-width: 1px;
    }
}


@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant(
            $value,
            $value,
            color-contrast($value),
            shade-color($value, 15%),
            shade-color($value, 15%)
        );
        border-bottom-color: shade-color($value, 25%);
        &:hover,
        &:focus {
            border-top-color: $value;
        }
    }
}

.btn-orange {
    @include button-variant(
        $orange,
        $orange,
        color-contrast($orange),
        shade-color($orange, 15%),
        shade-color($orange, 15%)
    );
    border-bottom-color: shade-color($orange, 25%);
    &:hover,
    &:focus {
        border-top-color: $orange;
    }
}

// Overwriting color for primary button because even if contrast is technically higher with black
// it looks way better with white
.btn-primary {
    color: white;
    &:hover,
    &:active,
    &:focus {
        color: white;
    }
}

// Card
.card {
    @extend .shadow-sm;
    border: 1px solid rgba(0, 0, 0, 0.02);
}

// Form
.form-control,
.form-select {
    border-bottom-width: 2px;
}

input {
    accent-color: $secondary;
}

::selection {
    background-color: tint-color($secondary, 40%);
}

select option:focus,
select option:active,
select option:checked {
    background-color: tint-color($primary, 60%);
}

select option:hover:not(option:checked) {
    background-color: tint-color($primary, 90%);
}

select[multiple]:focus option:checked {
    background: $primary linear-gradient(0deg, $primary 0%, $primary 100%);
}

// SELECT 2
.select2-container--default .select2-selection--multiple {
    border: 1px solid #eaeaea;
    border-bottom-width: 2px;
    min-height: 38px;
    border-radius: 8px;
    line-height: 1.6;
}
.select2-container .select2-search--inline .select2-search__field {
    font-family: $font-family-sans-serif;
}
.select2-container--default
    .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: tint-color($primary, 30%);
}
.select2-container--default.select2-container--focus
    .select2-selection--multiple {
    border-color: #80d0cd;
    box-shadow: 0 0 0 0.25rem rgba(0, 161, 154, 0.25);
    border-bottom-width: 2px !important;
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: tint-color($primary, 80%);
    border: 1px solid tint-color($primary, 60%);
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    border-right-color: tint-color($primary, 60%);
    color: #212529;
}
.select2-dropdown {
    box-shadow: $box-shadow;
    border-radius: 8px;
    border-color: #dadada;
}

// PAGINATION
.page-link {
    border-bottom-width: 2px;
    font-weight: 500;
    &:hover,
    &:focus,
    &:active {
        border-top-width: 2px;
        border-bottom-width: 1px;
    }
}

// FOOTER
footer {
    background: shade-color($primary, 25%);
    width: 100%;
    > div {
        max-width: 1280px;
    }
    #footer-logo {
        width: 125px;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
    p {
        margin-bottom: 0 !important;
    }
    box-shadow: 0 -3rem 3rem shade-color($primary, 30%) inset;
}
