.informativa-cookies {
    color: #5e5e5e;
    font-size: 1rem;
    section {
        margin-bottom: 2rem;
    }
    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        li:before {
            content: "- ";
        }
    }
    // .section-fourth ul:first-of-type li {
    //     display: inline-block;
    //     &:before {
    //         content: "";
    //         span:not(:first-of-type) {
    //             &::before{
    //                 content: ",";
    //             }
    //         }
    //     }
    // }
    h1 {
        margin-bottom: 1.5rem !important;
    }
    h5 {
        margin-top: .5rem;
    }
    h1,h5 {
        text-transform: uppercase;
    }
    p {
        margin-bottom: 0;
    }
}
