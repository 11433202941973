// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Manrope', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$darkOrange: #e08b35;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #00a19a;
$secondary: #91c23d;

/*My styles
Color extensively used in the landing page and in the app. blade layout for the navbar links*/
$grey: #5e5e5e;
$biroserDarkGreen: #016f45;
$biroserDarkGreenHover: #045e3c;

// App buttons colors
$mynewcolor:#0d80b6;
$mynewcolorhover:#0d6994;
$landingPageButton: #ffeb80;
$landingPageButtonHover: #dcc543;

//Navbar and footer sizes
$footerHeight: 130px;
$topNavbarHeight: 80px;

// Button
$btn-border-radius: 10px;
$btn-border-radius-sm: 8px;
$btn-border-radius-lg: 12px;
$btn-font-weight: 500;

// Card
$card-cap-bg: white;
$card-cap-padding-y: 20px;
$card-cap-padding-x: 24px;
$card-border-width: 0;
$card-border-radius: 16px;
$card-spacer-x: 24px;
$card-spacer-y: 20px;

// Form
$form-label-margin-bottom: 0;
$form-label-font-size: .85rem;
$form-label-color: #5e5e5e;
$input-border-color: #eaeaea;
$input-border-radius: 8px;
$form-select-border-radius: 8px;

// Pagination
$pagination-border-radius: 10px;
$pagination-border-radius-sm: 8px;
$pagination-border-radius-lg: 12px;

// Modal
$modal-content-border-radius: 10px;
$modal-content-border-width: 0;

// Nav
$nav-pills-border-radius: 8px;
